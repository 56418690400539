<template>
  <div id="rowform">
    <validation-observer ref="form">
      <form>
        <div id="form" v-for="field in fields" :key="field.label">
          <v-row v-if="field.type == 'text'" no-gutters>
            <v-col cols="12" id="">
              <ValidationProvider :name="field.label" :rules="field.rules">
                <v-text-field
                  v-model="field.value"
                  :placeholder="field.label"
                  regular
                  :readonly="field.readonly ? field.readonly : false"
                  class="fields"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row v-if="field.type == 'email'" no-gutters>
            <v-col cols="12" id="">
              <ValidationProvider :name="field.label" :rules="field.rules">
                <v-text-field
                  v-model="field.value"
                  regular
                  :placeholder="field.label"
                  :readonly="field.readonly ? field.readonly : false"
                  class="fields"
                  type="email"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row v-if="field.type == 'password'" no-gutters>
            <v-col cols="12" id="">
              <ValidationProvider :name="field.label" :rules="field.rules">
                <v-text-field
                  v-model="field.value"
                  regular
                  :placeholder="field.label"
                  class="fields"
                  type="password"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row v-if="field.type == 'phone'" no-gutters>
            <v-col cols="12" id="">
              <ValidationProvider :name="field.label" :rules="field.rules">
                <v-text-field
                  v-model="field.value"
                  regular
                  :placeholder="field.label"
                  :readonly="field.readonly ? field.readonly : false"
                  class="fields"
                  type="tel"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row v-if="field.type == 'select'" no-gutters>
            <v-col cols="12" id="">
              <ValidationProvider :name="field.label" :rules="field.rules">
                <v-select
                  :items="options"
                  v-model="field.value"
                  :label="field.label"
                  item-text="marche"
                  item-value="tag"
                ></v-select>
              </ValidationProvider>
            </v-col>
          </v-row>
        </div>
        <v-row align="center" no-gutters class="buttons">
          <template v-if="btn.length > 1">
            <v-col xl="6" lg="6" v-for="button in btn" :key="button.label">
              <v-btn
                v-if="button.type == 'btn'"
                width="100%"
                class="connect"
                :elevation="0"
                @click="validate(button.method)"
                >{{ button.label }}</v-btn
              >
              <p
                @click="forgetPassword"
                class="forget-password"
                v-if="button.type == 'link'"
              >
                mot de passe perdu ?
              </p>
            </v-col>
          </template>
          <template v-if="btn.length == 1">
            <v-col
              cols="12"
              v-for="button in btn"
              :key="button.label"
              class="px-0"
            >
              <v-btn
                v-if="button.type == 'btn'"
                width="100%"
                class="connect fullwidth"
                :elevation="0"
                @click="validate(button.method)"
                >{{ button.label }}
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </form>
    </validation-observer>

    <div id="snack">
      <v-snackbar
        v-for="error in errors"
        :key="error"
        v-model="snackbar"
        :timeout="timeout"
        right
        rounded="pill"
        color="red darken-2"
        elevation="24"
      >
        {{ error }}
      </v-snackbar>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      snackbar: false,
      timeout: 3000,
      errors: [],
    };
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    btn: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    forgetPassword: function() {
      this.$router.push({ name: "forgetPassword" });
    },

    validate: function(event) {
      if (event == "connect") {
        this.$refs.form.validate().then((valid) => {
          if (valid) {
            this.$emit("formOK");
          } else {
            //display error
            console.log(valid);
            this.errors = [];
            let e = this.$refs.form.errors;
            for (const field in e) {
              e[field].forEach((msg) => {
                this.errors.push(msg);
              });

              this.snackbar = true;
            }
          }
        });
      } else if (event == "cancel") {
        this.$emit("cancel");
      }
    },
  },
};
</script>

<style scoped>
.fields >>> label {
  font-size: 0.8rem !important;
  color: #000 !important;
  font-weight: bold;
  letter-spacing: -0.5px;
}

.fields >>> input::placeholder {
  font-size: 0.8rem !important;
  color: #000 !important;
  font-weight: bold;
  letter-spacing: -0.5px;
}

.fields >>> .v-label--active {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: bold;
  letter-spacing: -0.5px;
}

.buttons .connect {
  background-color: #af2c70;
  color: #fff;
  border-radius: 50px;
  padding: 5px 25px;
  font-weight: 600;
  letter-spacing: 0;
  font-size: 1rem;
  cursor: pointer;
}

.buttons .forget-password {
  font-size: 0.8rem;
  font-weight: 500;
  text-decoration: underline;
  text-transform: lowercase;
  color: #000;
  cursor: pointer;
  margin-left: 5px;
  margin-top: 18px;
  text-align: right;
}
#rowform span {
  width: 100%;
}
.fields >>> input:-webkit-autofill,
.fields >>> input:-webkit-autofill:hover,
.fields >>> input:-webkit-autofill:focus,
.fields >>> input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

#snack {
  position: fixed;
  top: 0;
  right: 0;
}
.theme--light.v-input >>> input::placeholder {
  color: #000 !important;
  opacity: 0.87;
}

@media screen and (max-width: 1366px) {
  .connect {
    width: 110% !important;
  }
}

@media screen and (max-width: 1100px) {
  .connect {
    width: 100% !important;
  }
  .forget-password {
    width: 110%;
  }
  .connect.fullwidth {
    font-size: 1.1rem;
  }
}
</style>
